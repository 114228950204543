<template>
  <button class="close-spalla" @click="close"></button>
</template>
<script>
export default {
  methods: {
    close() {
      this.$emit("close-button");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.close-spalla {
  pointer-events: all;
  //position: absolute;
  position: fixed;
  right: 0;
  //top: 0;
  top: 112px;
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  background: $primaryColor;
  background-image: url(../assets/close-icon.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  padding: 8px;
  color: #fff;
  z-index: 4;
}

@media (min-width: 992px) {
  .close-spalla {
    width: 80px;
    height: 80px;
    padding: 15px;
  }
}

@media (min-width: 1024px) {
  
  .close-spalla {
    right: 0px;
  }

  #map-container .close-spalla {
    position: absolute;
    top: 112px;
  }
}

@media (min-width: 1500px) {
}

</style>
