<template>
    <div class="main-container reperto-wrapper" v-if="repertoData && routesData">
        <div class="intro-reperto dark-bg ">
            <div class="background-cut"></div>

            <div>
                <div class="image container">
                    <images :imagedata="repertoData" :fieldname="'field_reperto_foto'"
                        :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                </div>
                <div class="info container">
                    <h3 class="time">{{ repertoData.attributes.field_reperto_periodo }}</h3>
                    <h1 class="title">{{ repertoData.attributes.title }}</h1>
                    <a @click.prevent="scrollToVideo" class="tip"
                        v-if="this.videoUrl!=''">
                        {{ this.$store.state.labelData[this.lang].watch }}
                    </a>
                    <!--
                    v-if="repertoData.attributes.field_reperto_sottotitolo && this.videoUrl!=''"
                        v-html="repertoData.attributes.field_reperto_sottotitolo.value"
                    -->
                </div>
                <a class="prev-reperto" v-if="this.previtem.attributes" @click.prevent="gotoReperto(previtem)"></a>
                <a class="next-reperto" v-if="this.nextitem.attributes" @click.prevent="gotoReperto(nextitem)"></a>
            </div>
        </div>
        <SpallaVideo v-if="this.showSpalla" :spalladata="this.spallaData" @close-spalla="toggleSpalla('close')" />
        <div class="main-content video" id="tovideo">
            <div class="container">
                <div :class="['video-container', 'storia', { videoPlaying: isPlaying }]" @click="playVideo()">
                    <div class="controls">
                        <img class="play" src="../../assets/play-button.svg" width="600" height="400" alt="">
                        <img class="pause" src="../../assets/pause-button.svg" width="600" height="400" alt="">
                    </div>

                    <video ref="video" preload="none" @ended="onEnd()" poster="../../assets/video/poster-placeholder-video.jpg"
                        :src="videoUrl" type="video/webm">
                        <!--
                            <source :src="videoUrl" type="video/webm">
                            Your browser does not support the video tag.
                        -->
                    </video>
                </div>
                <button class="testo-toggler btn icon-right outline testo-video" @click="toggleSpalla('video')">
                    <span>{{ this.$store.state.labelData[this.lang].video_button }}</span>
                </button>
            </div>
        </div>
        <div class="main-content dark-bg approfondimenti" v-if="approfReperto && approfReperto.length>0">
            <div class="container" style="padding-top: 1em;">
                <h2>{{ this.$store.state.labelData[this.lang].itemdetail.approfondimenti }}</h2>
            </div>
            <div class="container approfondimenti">
                <div class="tiles">
                    <div class="insight-tile" v-for="(appr, index) in approfReperto" :key="'insight'+index"
                        @click="toggleSpalla(appr.attributes.drupal_internal__nid)">
                        <div class="image">
                            <images :imagedata="appr" :fieldname="'field_approf_foto'"
                                :breakstyles="{'0': 'xs_image', '576': 'sm_image'}"></images>
                        </div>
                        <div class="title">
                            {{ appr.attributes.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-content" v-if="repertoData.field_reperto_luogo">
            <div class="location">
                <div class="container-title">
                    <h2 class="container">{{ this.$store.state.labelData[this.lang].itemdetail.luogo }}</h2>
                </div>
                <div class="image">
                    <images :imagedata="repertoData" :fieldname="'field_reperto_luogo'"></images>
                </div>
            </div>
        </div>
        <div class="main-content gray-bg last">
            <div class="container" v-if="approfLuogo && approfLuogo.length>0">
                <div class="tiles">
                    <div class="insight-tile" v-for="(appr, index) in approfLuogo" :key="'insight'+index"
                        @click="toggleSpalla(appr.attributes.drupal_internal__nid)">

                        <div class="image">
                            <images :imagedata="appr" :fieldname="'field_approf_foto'"  :breakstyles="{'0': 'xs_image', '576': 'sm_image'}"></images>
                        </div>

                        <div class="title">
                            {{ appr.attributes.title }}
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="container buttons">
                <router-link class="back-antiquarium btn " :to="storiaLink">
                    {{ this.$store.state.labelData[this.lang].itemdetail.button1 }}</router-link>
                <a v-if="this.nextitem.attributes" class="prox-reperto btn dark-bg"
                    @click.prevent="gotoReperto(nextitem)">{{ this.$store.state.labelData[this.lang].itemdetail.button2 }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import SpallaVideo from '@/components/SpallaVideo'
    import Images from '@/components/Images'
    import {
        fetchNodes,
        fetchRoutes
    } from '../../libs/drupalClient'
    export default {
        name: "reperto-detail",
        data: () => {
            return {
                repertoData: null,
                approfReperto: [],
                approfLuogo: [],
                isPlaying: false,
                showSpalla: false,
                spallaData: {
                    spallaTitle: "",
                    spallaContent: {},
                    spallaImages: "",
                    spallaTexts: "",
                    textsIndex: [],
                    spallaCover: "",
                    spallaTipo: ""
                },
                ids: [],
                routesData: [],
                nextitem: {},
                previtem: {},
                newReperto: null
            }
        },
        components: {
            SpallaVideo,
            Images
        },
        metaInfo() {
            return {
                title: this.objecttitle,
                meta: [{
                    property: 'og:title',
                    content: this.objecttitle
                }]
            }
        },
        computed: {
            pagemetatitle() {
                if(this.lang == 'it') return 'Reperto Storico'
                else if(this.lang=='de') return 'Historisches Artefakt'
                else return 'Historical Artifact'
            },
            videoUrl() {
                if(this.repertoData.field_reperto_video)
                    return process.env.VUE_APP_ENDPOINT + this.repertoData.field_reperto_video.attributes.uri.url
                else return ''
            },
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            },
            selectedRepertoID() {
                return this.$store.state.selectedRepertoID
            },
            objectid() {
                var p = []
                p = this.routesData.filter(r => r.path == this.$route.fullPath)
                if(p.length)
                    return p[0].nid
                else return 0
            },
            objecttitle() {
                var t = ''
                if(this.repertoData)
                    t = this.repertoData.attributes.title
                return t
            },
            route() {
                return this.$route
            },
            ctTitle() {
                //parametro dove si salva la route completa per la pagina del reperto,
                //per controllare che esista la combinazione LANG + NID + TITOLO, per il CT selezionato
                var t = (this.lang == this.defaultLang ? '' : ('/' + this.lang)) + "/not-found"
                this.routesData.forEach(f => {
                    if (f.lang == this.lang && f.nid == this.objectid)
                        t = f.path
                })
                return t
            },
            storiaLink() {
                return this.routesData.find(f => f.component_name == 'LaStoria' && f.lang == this.lang).path
            }
        },
        methods: {
            //FUNZIONI VIDEO
            play() {
                //console.log(this.$refs.video);
                this.$refs.video.play()
            },
            pause() {
                //console.log('pausepause');
                this.$refs.video.pause()
            },
            load(){
                this.$refs.video.load();
            },
            onEnd() {
                this.isPlaying = false
            },
            playVideo() {
                if (!this.isPlaying) {
                    this.play()
                    this.isPlaying = true
                } else {
                    this.isPlaying = false
                    this.pause()
                }
            },
            scrollToVideo() {
                window.scrollBy({
                    top: document.getElementById('tovideo').getBoundingClientRect().top - 150,
                    behavior: 'smooth'
                })
            },
            changeVideo() {
                this.pause()
                this.isPlaying = false
                this.$refs.video.src = this.videoUrl
            },
            toggleSpalla(id) {
                //evento lanciato da un pulsante "leggi testo del video"
                if (id == 'video') {
                    //console.log('open spalla MAIN VIDEO', this.repertoData)

                    this.spallaData.spallaTitle = this.repertoData.attributes.title
                    this.spallaData.spallaContent = this.repertoData
                    this.spallaData.spallaCover = "field_reperto_foto"
                    this.spallaData.spallaTexts = "field_ref_testi_video"
                    this.spallaData.spallaImages = "field_reperto_immagini_del_video"
                    this.spallaData.spallaTipo = "Testo video"

                    //evento lanciato dalla spalla per chiuderla
                } else if (id == 'close') {
                    //console.log('close spalla')

                    //evento lanciato da una tile di approfondimento
                } else {
                    //console.log('open spalla APPROFONDIMENTO', id)
                    //variabile dove salvare i dati dell'elemento da mostrare
                    var ar = null

                    //cerca approfondimento tra quelli relativi al reperto
                    ar = this.approfReperto.filter(a => a.attributes.drupal_internal__nid == id)[0]
                    //se non lo trova cerca anche tra quelli relativi al luogo
                    if (ar == undefined)
                        ar = this.approfLuogo.filter(a => a.attributes.drupal_internal__nid == id)[0]

                    //se lo ha trovato, costruisce i dati da passare alla spalla
                    if (ar) {
                        this.spallaData.spallaTitle = ar.attributes.title
                        this.spallaData.spallaContent = ar
                        this.spallaData.spallaCover = "field_approf_foto"
                        this.spallaData.spallaTexts = "field_ref_testi_approf"
                        this.spallaData.spallaImages = "field_immagini_approfondimento"
                        this.spallaData.spallaTipo = "Testo pagina"
                    }
                }

                //toggle della spalla
                this.showSpalla = !this.showSpalla
            },
            gotoReperto(r) {
                //console.log('goto item', r.attributes.drupal_internal__nid)

                //funzione per passare al prossimo (o precedente reperto) nella timeline
                this.$store.commit('SET_SELECTED_REPERTO', r.attributes.drupal_internal__nid)
                this.newReperto = r  
            },
            async findCT() {
                //fetche reperti senza filtri perchè serve sapere quali sono precedente e successivo (se esistono)
                await fetchNodes('reperto', {
                    include: ['field_reperto_foto', 'field_reperto_luogo', 'field_ref_approfondimenti',
                        'field_reperto_video', 'field_ref_testi_video',
                        'field_reperto_immagini_del_video'
                    ],
                }, this.lang)
                .then(res => {
                    //ordina per periodo storico (ordine alfabetico, ordine effettivo TO DO)
                    /* var rr = res.sort((a, b) => {
                        return a.attributes.field_reperto_periodo < b.attributes
                            .field_reperto_periodo ? -1 : 1
                    }) */

                    //salva reperto attuale, precedente e successivo (se esistono)
                    res.forEach((r, index) => {
                        if (r.attributes.drupal_internal__nid == this.selectedRepertoID) {
                            this.repertoData = res[index]
                            if (index < res.length - 1)
                                this.nextitem = res[index + 1]
                            if (index > 0)
                                this.previtem = res[index - 1]
                        }
                    })

                    /*console.log('repertoData \t', this.repertoData)
                    
                    if(this.previtem.attributes)
                        console.log('prevItem \t\t', this.previtem.attributes.title, this.previtem.attributes.drupal_internal__nid)
                    else console.log('prevItem \t\t none')

                    if(this.nextitem.attributes)
                        console.log('nextItem \t\t', this.nextitem.attributes.title, this.nextitem.attributes.drupal_internal__nid)
                    else console.log('nextItem \t\t none') */

                    //salva i NID degli approfondimenti del reperto attuale
                    //console.log(this.repertoData.field_ref_approfondimenti)
                    if (this.repertoData.field_ref_approfondimenti.length > 0)
                        this.repertoData.field_ref_approfondimenti.forEach(f => {
                            //console.log(f)
                            this.ids.push(f.attributes.drupal_internal__nid)
                        })
                })

                //cerca tutti gli approfondimenti, filtra per appartenenza al reperto attuale e dividi per tipologia
                await fetchNodes('approfondimento', {
                        include: ['field_approf_foto', 'field_immagini_approfondimento',
                            'field_ref_testi_approf'
                        ]
                    }, this.lang)
                    .then(res => {
                        this.approfReperto = res.filter(f => 
                            this.ids.includes(f.attributes.drupal_internal__nid) 
                            && f.attributes.field_tipo_di_approfondimento == 'reperto')
                        this.approfLuogo = res.filter(f => 
                            this.ids.includes(f.attributes.drupal_internal__nid) 
                            && f.attributes.field_tipo_di_approfondimento == 'luogo')
                    })
            }
        },
        async mounted() {
            //console.log('***** REFRESH *****')

            //salva tutte le routes
            await fetchRoutes().then(res => {
                this.routesData = res.routes
                //imposta il reperto selezionato a livello di visibilità superiore per tutti

                if(this.selectedRepertoID==0)
                    this.$store.commit('SET_SELECTED_REPERTO', this.objectid)

                //console.log('nuovo item:', this.objectid, this.selectedRepertoID)
                //console.log(this.ctTitle, this.$route.fullPath)

                //e controlla se la route per il CT selezionato (route.fullPath)
                //corrisponde alla vera route per il CT con quel NID (ctTitle)
                if (this.ctTitle != this.$route.fullPath) {
                    //se non esiste (controllo fatto da ctTitle = id e titolo presenti), manda alla pagina di errore
                    this.$router.push((this.lang == this.defaultLang ? '' : ('/' + this.lang)) + "/not-found")
                } else {
                    //se non è stato mandato alla pagina di errore, carica il contenuto
                    this.findCT()
                }
            })
        },
        watch: {
            //nuovo fetch quando uso i pulsanti "back" o "forward" del browser
            route() {
                this.$store.commit('SET_SELECTED_REPERTO', this.objectid)
                this.findCT()
                this.changeVideo()
            },
            //watcher per il cambio di lingua
            lang() {
                this.showSpalla = false
                //console.log(this.showSpalla, this.cTitle)
                this.$router.push(this.ctTitle)
                this.findCT()
                this.changeVideo()
            },
            //watcher per l'evento di passaggio da un reperto all'altro con i pulsanti
            selectedRepertoID() {
                //check per evitare il trigger al primo caricamento della pagina
                if (this.newReperto != null) {
                    //console.log(this.newReperto)

                    //annulla le impostazioni attuali
                    this.nextitem = {}
                    this.previtem = {}
                    this.ids = []

                    //calcola la route del reperto a cui mandare l'utente
                    var url = this.routesData.filter(f => 
                        f.nid == this.newReperto.attributes.drupal_internal__nid 
                        && f.lang == this.lang )[0]

                    //console.log(url, this.selectedRepertoID)
                    if (url == undefined)
                        this.$router.push((this.lang == this.defaultLang ? '' : ('/' + this.lang)) + "/not-found")
                    else
                        this.$router.push(url.path)

                    //calcola i dati del nuovo reperto
                    this.findCT()

                    //ricarica video
                    this.changeVideo()

                    //annulla le impostazioni attuali
                    this.newReperto = null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables.scss";

    .container.buttons a.prox-reperto {
        background-color: #3A3C4D;
    }

    #app .intro-reperto .background-cut {
        background: #181923 url("../../assets/san-canzian-filigrana-reperto.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        width: 100%;
        transform: skewY(-5deg);
        height: 100vh;
        padding: 10rem 0rem 0rem 0rem;
        top: -5rem;
        transform: none;
    }

    .btn.testo-video {
        max-width: 320px;
        margin: 2rem auto 0px auto;
        display: block;
        text-align: center;
        border-color: #3A3C4D;
        color: #3A3C4D;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 0.875rem;
    }

    .test-toggler::before {
        margin-left: 194px;
        transform: translateY(-50%) scale(0.8);
        transform-origin: center;
    }

    .main-content {
        margin-top: 0;
        padding: 0;
    }

    .last {
        padding: 2rem 0;
    }

    .approfondimenti {
        padding: 0 0 2rem 0;
    }

    .reperto-wrapper {
        position: relative;
    }

    div.dark-bg.approfondimenti {
        background: url("../../assets/filigrana-blue.jpg");
    }

    div.dark-bg {
        background-color: $secondaryColor;
        color: #fff;
        padding-top: 10rem;
        margin-top: -2rem;
        padding-bottom: 5rem;

        >div {
            display: flex;
            flex-direction: column-reverse;
        }

        .prev-reperto {
            transform: rotateZ(180deg);
        }
    }

    .video-container.storia {
        margin-top: 20px;
    }

    .approfondimenti.dark-bg {
        padding-top: 4rem;
        margin-top: 4rem;
    }

    .gray-bg {
        background: url("../../assets/filigrana-light--grey.jpg");
    }

    .location {
        max-height: 80vh;
        overflow: hidden;

        >.container-title {
            position: relative;
            background-color: #fff;
            z-index: 2;
        }

        h2 {
            color: $secondaryColor;
            margin-top: 0;
            margin-bottom: 0;
            padding: 4rem 0rem 0rem;
            margin-left: 15px;
        }

        .image {
            height: 70vh;
            display: flex;
            align-items: center;
            position: relative;
            background-color: #f3f3f6;
            justify-content: center;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 183px;
                z-index: 5;
                width: 100%;
                background: url("../../assets/white-triangle-sep.svg");
            }


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                max-width: 1170px;
                margin: 0px auto;

                width: 100%;
                position: absolute;
                object-fit: cover;
            }
        }
    }

    .approfondimenti {
        h2 {
            margin-top: 0;
        }

        .tiles .insight-tile {
            color: #fff;
        }
    }

    .main-content h2 {
        word-break: break-all;
        font-size: 1.25rem;
    }

    .tiles {
        display: flex;
        flex-direction: column;

        .insight-tile {
            margin: 1rem 0;
            width: 100%;
            position: relative;

            &:before {
                content: '';
                background-image: url(../../assets/arrow-icon-circle-y.svg);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                width: 2.75rem;
                height: 2.75rem;
                display: block;
                position: absolute;
                left: 1rem;
                top: 0;
                transform: translateY(-.25rem);
                transition: all 300ms ease;
            }

            &:hover {
                &::before {
                    left: 20px;
                    transition: all 300ms ease;
                }

                .title {
                    color: $primaryColor;
                    transition: all 300ms ease;
                }
            }

            .image {
                display: none;
            }

            .title {
                padding-left: 22%;
                font-size: 1rem;
                transition: all 300ms ease;
            }
        }
    }

    .container.buttons {
        transform: translateY(2rem);
        padding: 0;
        width: 100%;
        max-width: unset;

        a {
            font-size: 1rem;
            max-width: unset;
            text-decoration: none;
            margin: 0;
            color: #fff;
            padding: 4rem 2rem;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 50px;
                height: 50px;
                left: 5%;
                top: 5em;
                display: block;
            }

            &.back-antiquarium {

                &::before {
                    background-image: url(../../assets/back-monumento-arrow.svg)
                }

                &:after {
                    content: '';
                    background-color: $secondaryColor;
                    display: block;
                    height: 25%;
                    width: 100%;
                    position: absolute;
                    bottom: -25%;
                }
            }

            &.prox-reperto {

                &:before {
                    background-image: url(../../assets/back-colonna-arrow.svg)
                }
            }
        }
    }

    .intro-reperto {
        padding-top: 92px;
        padding-bottom: 120px;
        //height: 70vh;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        position: relative;

        .prev-reperto,
        .next-reperto {
            position: absolute;
            background-image: url(../../assets/arrow-icon-circle-y.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 60px;
            height: 60px;
            bottom: 50px;

            &:hover {
                opacity: .5;
                background-image: url(../../assets/arrow-icon-circle-w.svg);
            }
        }

        .prev-reperto {
            left: 25%;
        }

        .next-reperto {
            right: 25%;
        }

        .image {
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 2rem;
            height: 100vw;

            picture {
                position: absolute;
                width: 100%;
                height: 100%;
                transform: scale(0.8);
            }

            &::before {
                content: '';
                width: 90%;
                height: 90%;
                max-width: 450px;
                max-height: 450px;
                background-color: #2a2e3666;
                border-radius: 50%;
                position: absolute;
                display: block;
                z-index: 0;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            img,
            ::v-deep picture img {
                position: absolute;
                z-index: 3;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            text-align: center;
            position: relative;
            z-index: 3;

            .tip {
                display: none;
            }

            .time {
                text-transform: none;
                font-family: $secondaryFont;
                color: $greenColor;
                //font-weight: 700;
                //font-size: 1.125rem;
                font-size: 1.5rem;
                margin-bottom: 0;
            }

            .title {
                font-family: $primaryFont;
                font-size: 1.4rem;
                line-height: 1.45;
                margin-top: .5em;
                color: #fff;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    .container.buttons {
        margin-top: 0rem;
        max-width: 100%;

        a {
            display: block;
            width: 100%;
            padding: 3rem 0rem;
            text-align: center;

            &:before {
                top: 50%;
            }
        }
    }

    @media (min-width: 440px) {
        .tiles .insight-tile::before {
            left: 2rem;
        }
    }

    @media (min-width: 576px) {


        .btn.testo-video {
            width: 320px;
        }

        #app .intro-reperto .background-cut {
            padding: 10rem 0rem;
        }

        #app .intro-reperto .background-cut {
            transform: skewY(-5deg);
        }

        div.dark-bg:not(.approfondimenti) {
            height: 100vh;
            padding-bottom: 0rem;

            >div {
                height: 100%;
            }
        }

        .dark-bg a.prev-reperto,
        .dark-bg a.next-reperto {
            transform: translateY(50%) rotateZ(0deg);
            bottom: auto;
            top: 50%;
            position: absolute;
        }

        .dark-bg a.prev-reperto {
            transform: translateY(50%) rotateZ(180deg);
        }

        .intro-reperto .image picture {
            transform: scale(1);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .intro-reperto .image ::v-deep picture img {
            width: 80%;
            height: 80%;
        }

        .intro-reperto {
            padding-bottom: 50px;

            >div {
                position: relative;
            }

            .prev-reperto {
                left: 5%;
                top: 50%;
                z-index: 4;
            }

            .next-reperto {
                right: 5%;
                top: 50%;
                z-index: 4;
            }
        }

        .container.buttons {
            a::before {
                left: 15%;
                width: 60px;
                height: 60px;
            }
        }
    }

    @media (min-width: 768px) {

        .container.buttons a.back-antiquarium,
        .container.buttons a.prox-reperto {
            max-width: 190px;
        }


        .intro-reperto .info .title {
            font-size: 1.75rem;
        }

        .intro-reperto .image {
            height: 100%;
        }

        .gray-bg {
            background-color: #e0e0e0;
        }

        .tiles {
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-start;

            .insight-tile {
                width: 31%;
                margin: 0 1.167% 10px 1.167%;
                transition: all 300ms ease;

                &::before {
                    display: none;
                }

                .image {
                    display: block;
                    width: 100%;
                    aspect-ratio: 0.9;
                    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 95%);
                    position: relative;

                    img,
                    ::v-deep picture img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }

                    ::v-deep picture {
                        width: 100%;
                    }

                    &::before {
                        content: '';
                        background-image: url(../../assets/arrow-icon-circle-w.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        width: 3.5rem;
                        height: 3.5rem;
                        display: block;
                        position: absolute;
                        left: 5%;
                        bottom: 3rem;
                        transform: translateY(-.25rem);
                        transition: all 300ms ease;
                        z-index: 4;
                    }
                }

                .title {
                    padding: 0 1em;
                    font-size: 1.256rem;
                }

                &:hover {
                    cursor: pointer;

                    .title {
                        color: $primaryColor;
                    }

                    .image {
                        &::before {
                            left: 15%;
                        }

                        &::after {
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: rgba(#000, .5);
                            position: absolute;
                            display: block;
                            top: 0;
                            left: 0;
                            clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 95%);
                            transition: all 300ms ease;
                        }
                    }

                }
            }
        }

        .last .container:first-child:not(.buttons) {
            margin-bottom: 3rem;
        }

        .container.buttons {
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            max-width: 720px;
            margin: 0 auto;
            transform: none;

            a.back-antiquarium,
            a.prox-reperto {
                clip-path: none;
                padding: 2rem 4rem 2rem 6rem;

                &::before {
                    left: 5%;
                    top: 50%;
                    width: 50px;
                    height: 50px;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .intro-reperto {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-left: 10%;
            padding-right: 10%;

            .info {
                width: 50%;
                text-align: left;

                .title {
                    line-height: 3rem;
                    font-size: 2.25rem;
                }

                .tip {
                    display: block;
                    color: $primaryColor;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    padding-left: 50px;
                    position: relative;

                    &::before {
                        content: '';
                        width: 30px;
                        height: 30px;
                        background-image: url(../../assets/video-icon.svg);
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        left: 0;
                        display: block;
                    }
                }
            }

            .image {
                width: 50%;

                &::before {
                    top: calc(50% + 50px);
                }

                img,
                ::v-deep picture img {
                    object-fit: contain;
                    max-width: unset;
                    max-height: unset;
                    width: 100%;
                    height: 100%;
                    top: 50px;
                }

                ::v-deep picture {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .tiles {
            .insight-tile {
                margin: 0 1% 10px 1%;
                width: 23%;
            }
        }

        .container.buttons {
            max-width: 960px;

            a.back-antiquarium,
            a.prox-reperto {
                &::before {
                    left: 10%;
                }

                padding: 2rem 4rem 2rem 8rem;
            }
        }
    }

    @media (min-width: 1024px) {
        .main-content h2 {
            font-size: 2.25rem;
        }

        .location h2 {
            margin: 0 auto;
        }

        div.dark-bg {
            padding-top: 0rem;
            margin: 0;
        }

        .intro-reperto .info {
            max-width: 530px;
            padding-left: 0;
            margin-left: 0;
        }

        div.dark-bg:not(.approfondimenti)>div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .intro-reperto .image {
            height: 70%;
        }

        div.dark-bg:not(.approfondimenti) {
            width: auto;

            >div {
                flex-direction: row;
                width: 100%;
            }
        }

        .intro-reperto .prev-reperto {
            left: -70px;
        }

        .intro-reperto .next-reperto {
            right: -70px;
        }

        .intro-reperto .info {
            justify-content: center;
        }

        .location .image img {
            transform: scale(1.2);
        }

    }

    @media (min-width: 1200px) {

        .intro-reperto .info {
            max-width: 400px;
        }

        .intro-reperto .info .title {
            line-height: 3.7rem;
            font-size: 2.875rem;
        }

    }
</style>