<template>
    <div class="video-external">
        <div class="container" v-if="spalladata.spallaTexts">

            <!--<button class="close-spalla" @click="closeSpalla"></button>-->
            <LeftTopButton @close-button="closeSpalla" />

            <h2 class="title" v-if="spalladata.spallaTitle">{{ spalladata.spallaTitle }}</h2>

            <!-- <figure v-if="spalladata.spallaCover!=''">
                <images :imagedata="spalladata.spallaContent" :fieldname="spalladata.spallaCover" :breakstyles="{'0': 'md_image', '768': 'lg_image'}"></images>
            </figure> -->
            
            <div v-for="(text, index) in finalTexts" :key="'spallatext'+index">
                <h3 v-if="text.attributes.field_ui_text_titolo">
                    {{ text.attributes.field_ui_text_titolo.value }}
                </h3>

                <p v-if="text.attributes.field_text_with_summary" 
                    v-html="text.attributes.field_text_with_summary.summary">
                </p>

                <p v-if="text.attributes.field_text_with_summary" 
                    v-html="text.attributes.field_text_with_summary.value">
                </p>
            </div>

            

            <figure v-for="(img, index) in spalladata.spallaContent[spalladata.spallaImages]" :key="'spallaimg'+index">
                <images showcaption :imagedata="spalladata.spallaContent" :fieldname="spalladata.spallaImages" :resultindex="index" :breakstyles="{'0': 'md_image', '768': 'lg_image'}"></images>
            </figure>

        </div>
    </div>
</template>

<script>
import Images from '../components/Images.vue'
import LeftTopButton from '../components/LeftTopButton.vue'
export default {
    props: { 
        spalladata: Object
    },
    components: {
        Images,
        LeftTopButton
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        finalTexts() {
            return this.spalladata.spallaContent[this.spalladata.spallaTexts].filter(f => f.attributes.field_tipo ==  this.spalladata.spallaTipo)
        }
    },
    methods: {
        closeSpalla() {
            document.body.classList.remove("noscroll")
            this.$emit('close-spalla')
        },
        srcImage(r) {
            return process.env.VUE_APP_ENDPOINT + r.attributes.uri.url
        }
    },
    mounted() {
        //console.log(this.spalladata)
        document.body.classList.add("noscroll")
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
.video-external {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 9;
    overflow-y: scroll;
    overflow-x: hidden;

    .container {
        background: #fff;
        padding: calc(102px + 80px) 1em 1em 1em;
        margin: 0;
        max-width: unset;
        min-height: calc(100% - 80px - 1em);
        position: relative;

      
        .title { margin-top: 0; }
        figure {
            margin: 2rem auto;
        }
    }

    ::v-deep picture img, 
    img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: contain;
    }
}

@media(min-width: 1024px) {
    .video-external {
        height: calc(100vh - 112px);
        background: rgba(#000, .43);

        &:before {
            position: fixed;
            bottom: 0;
            content: "";
            height: calc( 100vh - 112px );
            left: calc( 50% + -329px);
            z-index: 9;
            width: 233px;
            background: url("../assets/taglio-spalla.svg");
            background-size: cover;
        }
        
        .container {
            padding:112px 4em 2em 2em;
            width: 50%;
            height: fit-content;
            margin-left: auto;
            position: relative;
            >div {
                margin-right: 50px;
            }
        }
    }
}
</style>